import React, { useEffect, useState } from 'react';
import gtConfig from './../config.js';
import { useOktaAuth } from '@okta/okta-react';
import {useTheme} from "@mui/material/styles";
import fetchDataFileUrl from './../services';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';

const Audit = ({ id, fileType, onClose }) => {
  
    const [data, setData] = useState([]);
    const [detailedView, setDetailedView] = useState(false);
    const { authState, oktaAuth } = useOktaAuth();

    const theme = useTheme();

    useEffect(() => {
      const fetchData = async () => {
          try {
              const url_request = gtConfig.ground_truth_back_end.timeMachineUrl + "?file_type="+ fileType + "&file_name="+ id
              const auditData = await fetchDataFileUrl(url_request, authState, oktaAuth);
              setData(auditData);
          } catch (error) {
              console.error('Error fetching Time Machine data:', error);
          }
      };

      fetchData();
  }, []);

    useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const jsonHandler = (value, index, prevValue) => {
    try {
      if (value.charAt(0) !== '{') {
        return value.replaceAll('_', '..\n_');
      }
      const jsonObject = JSON.parse(value.replaceAll(/'/g, '"').replaceAll('None', 'null'));

      // Format JSON as key-value pairs on separate lines
      const formattedJson = Object.entries(jsonObject)
        .map(([key, val]) => `${key}: ${val}`)
        .join('\n');

      return formattedJson; //formattedJson === prevValue ? 'no change' : formattedJson;
    } catch (error) {
      //console.error('Error parsing JSON:', error);
      return value; //value === prevValue ? 'no change' : value;
    }
  };

  const isInAcceptedColumns = (data, detailedView, index) => {
    if (detailedView ){
      return  true;
    } else {
      //Index of desired columns to show in summary mode
      const idexOfField = data[0].indexOf("Field");
      const idexOfExtractedValues = data[0].indexOf("Extracted Values"); 
      const idexOfStandardizedValues = data[0].indexOf("Standardized Values"); 

      const filteredColumns= [idexOfField, idexOfExtractedValues, idexOfStandardizedValues];
      return filteredColumns.includes(index);
    }
  }


  return (
    <Box className="audit-popup" sx={{backgroundColor: theme.palette.background.paper}}>
      <Box className="audit-popup-content" sx={{backgroundColor: theme.palette.background.paper}}>
        <span className="close" onClick={onClose}  sx={{ color: theme.palette.text.primary}}>&times;</span>
        <Typography sx={{ color: theme.palette.text.primary}}>Time Machine for ID: {id}   
          <Button 
            variant='contained' 
            onClick={() => setDetailedView( !detailedView)} 
            style={{marginLeft:'350px'}}
            >
            {detailedView ? 'Summary View' : 'Detailed View'}
          </Button>
        </Typography>
        { detailedView &&  Object.values(data).length === 0  &&
            <p> There is no data for  {id} </p>
        }

        { data.length > 0 &&
         <table>
          <thead>
            <tr style={{backgroundColor: theme.palette.background.paper}}>
              {data[0].map((header, index) => (
                 isInAcceptedColumns(data, detailedView, index) && (
                  <th key={header} style={{backgroundColor: theme.palette.background.paper}}>
                     <Typography variant='body1' sx={{ color: theme.palette.text.primary}}>{header}</Typography>
                  </th>
                )
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              rowIndex !==0 && <tr key={rowIndex}>
                {Object.entries(row).map(([key, value], colIndex) => (
                  (
                    isInAcceptedColumns(data, detailedView, colIndex) && 
                    <td key={colIndex} className="transition-cell" style={{backgroundColor: theme.palette.background.paper}}>
                      <pre>
                        <Typography variant='caption' sx={{ color: theme.palette.text.primary}}>
                            {jsonHandler(value, rowIndex, rowIndex > 0 ? data[rowIndex - 1][key] : '')}
                        </Typography>
                      </pre>
                    </td>
                  )
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      }
      </Box>
    </Box>
  );
};

export default Audit;