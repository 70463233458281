import zIndex from "@mui/material/styles/zIndex";

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '95%',
    backgroundColor: '#eeeeee',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  export const validationIssueModalStyle = {
    position: 'absolute',
    // display: 'flex', 
    // justifyContent: 'space-between',
    // overflow: "auto",
    height: "80%",
    minWidth: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#eeeeee',
    // border: '2px solid #000',
    // boxShadow: 24,
    padding: '10 50px',
    // gap: '10px',
  };

  export const uploadFileModalStyle = {
    position: 'absolute',
    height: "20%",
    minWidth: '40%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#eeeeee',
    padding: '10 50px',
  };

  export const attributeValidationIssueModalStyle = {
    position: 'absolute',
    height: "70%",
    minWidth: '80%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#eeeeee',
    padding: '10 50px',
  };


  export const modalAttributePopupStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export const loadingStyle = {
  pointerEvents: 'none',
  opacity: '0.5'
}

export const rightPanelButtonsStyle = {
  fontSize: '11px', 
  padding: '4px 8px', 
  minWidth: 'auto', 
  ml: .2
}