import { Box, Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import {useTheme} from "@mui/material/styles";

function SearchDocumentComponent({textDivs, divIdText, searchFeatureChecked, headerHeight, showloadPdfprogress}) {
    const [searchText, setSearchText] = useState('');
    const [currentResultIndex, setCurrentResultIndex] = useState(0);
    const [searchResults, setSearchResults] = useState(undefined);
    const [wordCount, setWordCount] = useState(0);

    const searchInputRef = useRef(null);
    const searchButtonRef = useRef(null);
    const nextButtonRef = useRef(null);
    const [isSearchClicked, setIsSearchClicked] = useState(false);

    const theme = useTheme();
    
    // Use this state to remove style from div when searchFeatureChecked is false
    const [selectedDivId, setSelectedDivId] = useState(0);


    useEffect(() => {
        setCurrentResultIndex(0);
        setSearchText("");
        setSearchResults(undefined);
    }, [textDivs]);

    useEffect(() => {
        if (!searchFeatureChecked){
            scrollToSelectedDivs(selectedDivId, false, wordCount)
        }
    }, [searchFeatureChecked]);

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Enter') {
            if (document.activeElement === searchInputRef.current) {
                searchButtonRef.current.focus();
                searchButtonRef.current.click();
                nextButtonRef.current.focus();
            } else if ( document.activeElement === searchButtonRef.current || document.activeElement === nextButtonRef.current){
                nextButtonRef.current.focus();
                nextButtonRef.current.click();
            }

          }
        };
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    const scrollToSelectedDivs = (divId, isSelect, wordCount) => {
        const styleValue = isSelect ? "5px solid blue" : "";
        for (let i = 0; i < wordCount; i++){
            const pdfOverlayElement = document.getElementById(divId + i);
            if (pdfOverlayElement) {
                pdfOverlayElement.style.borderBottom = styleValue;
                pdfOverlayElement.scrollIntoView({block: "start", inline: "nearest"}); // behavior: 'smooth', smooth behavior not working for long scrolls
                setTimeout(() => {
                    //adjust scroll down 20px so it isn't at very top
                    const padding = 10  + parseInt(headerHeight);
                    window.scrollBy(0, -padding);
                }, 100);
            }          
        }
    }

    const handleSearch = async () => {
        setIsSearchClicked(true);
        // clean previous div blue style
        if ( searchResults !== undefined && searchResults.length > 0 && currentResultIndex >= 0){
            scrollToSelectedDivs( searchResults[currentResultIndex], false, wordCount);
        }
        
        const words = searchText.trim().split(' ');
        setWordCount(words.length);
        const firstWord = words[0];
        const firstWordDivIds = textDivs.get(firstWord);
        let results = [];
        if ( firstWordDivIds && firstWordDivIds.length > 0){
            for ( let divId of firstWordDivIds ){
                if (isTextEqualToSearchText(words, divId)){
                    results.push(divId);
                }
            }
           
            if (results.length > 0){
                setSearchResults(results);
                setSelectedDivId(results[0]);
                scrollToSelectedDivs( results[0], true, words.length);
            } else {
                setSearchResults(undefined);
            }
            
        } else {
            setSearchResults(undefined);
        }
    
        setCurrentResultIndex(0);
    };

    const isTextEqualToSearchText = (words, divId) => {
        let text = words[0];
        for (let i = 1; i < words.length; i++){
            text = text + ' ' + divIdText.get(divId + i)
        }
        return text === searchText.trim();
    }

    const handleNextResult = () => {
        if (currentResultIndex < searchResults.length - 1) {
            setCurrentResultIndex(currentResultIndex + 1);
            setSelectedDivId(searchResults[currentResultIndex + 1]);
            scrollToSelectedDivs( searchResults[currentResultIndex], false, wordCount);
            scrollToSelectedDivs( searchResults[currentResultIndex + 1], true, wordCount);
        }
    };

    const handlePreviousResult = () => {
        if (currentResultIndex > 0) {
            setCurrentResultIndex(currentResultIndex - 1);
            setSelectedDivId(searchResults[currentResultIndex - 1]);
            scrollToSelectedDivs( searchResults[currentResultIndex], false, wordCount);
            scrollToSelectedDivs( searchResults[currentResultIndex - 1], true, wordCount);
        }
    };

    return (
        // <Box sx={{borderColor: theme.palette.divider, backgroundColor: theme.palette.background.paper}}>
        <Box sx={{borderColor: theme.palette.divider, width:"580px"}}>
            <input
                ref={searchInputRef}
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLocaleLowerCase())}
                placeholder="Search text..."
                disabled={!searchFeatureChecked || showloadPdfprogress}
            />
            <Button
                sx={{height: "22px", marginLeft: "2px"}}
                variant="contained" 
                size="small"
                ref={searchButtonRef}
                onClick={handleSearch}
                disabled={textDivs.size === 0 || !searchFeatureChecked || showloadPdfprogress}>Search
            </Button>
            <Button
                sx={{height: "22px", marginLeft: "2px"}}
                variant="contained" 
                size="small"
                onClick={handlePreviousResult}
                disabled={searchResults === undefined || currentResultIndex === 0 || !searchFeatureChecked || showloadPdfprogress}>Previous
            </Button>
            <Button 
                sx={{height: "22px", marginLeft: "2px"}}
                variant="contained" 
                size="small"
                ref={nextButtonRef}
                onClick={handleNextResult}
                disabled={searchResults === undefined || currentResultIndex === searchResults.length - 1 || !searchFeatureChecked || showloadPdfprogress}>Next
            </Button>
        </Box>
    )
}

export default SearchDocumentComponent