import React, {useState, useEffect} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Modal, Button, Box, Typography, Grid } from "@mui/material";
import { modalStyle } from "../styles";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import gtConfig from './../config.js';

const AttributesModal = ({ fileType, isOpen, onClose, handleAttributeModalSubmit, currentAttributes, attributeContext} ) => {
  const [newAttribute, setNewAttribute] = useState('');
  const [contextAttribute, setContextAttribute] = useState('');
  const [showAttributeContext, setShowAttributeContext] = useState(false);
  const [attributesTag, setAttributesTag] = useState(false);
  
  
  useEffect(() => {
    const attributes = provideAttributes(currentAttributes, fileType);

    const tags = attributes.map((attribute) => {
        return <MenuItem key={attribute} value={attribute}>{attribute}</MenuItem>
    });
    
    setAttributesTag(tags);
  
  },[fileType, currentAttributes]);

  useEffect(() => {
    // Reset the dropdown value when the modal is closed
    if (!isOpen) {
      setNewAttribute('');
    }
  }, [isOpen]);


  const provideAttributes = (currentAttributes, fileType) => {
    let allAttibutes = gtConfig.attributes_to_display[fileType];
    allAttibutes = [...new Set(allAttibutes)].filter((item) => !currentAttributes.has(item)).sort();
  
    return allAttibutes
  };


  const attributeContextTag = ["",...attributeContext].map((context) => {
    return <MenuItem key={context} value={context}>{context}</MenuItem>
  });

  const handleFormSubmit = () => {
    handleAttributeModalSubmit(newAttribute, contextAttribute);
  };

  const handleAttributesChange = (event) => {
    const attribute = event.target.value;
    setShowAttributeContext(true);
    setNewAttribute(attribute);
  }

  const handleAttributeContextChange = (event) =>{
    const attributeContext = event.target.value;
    setContextAttribute(attributeContext);
  }

  return (
    <Modal 
        open={isOpen} 
        onClose={onClose}
        aria-labelledby="List of Attributes"
        aria-describedby="List of Attributes"
        >
       <Box sx={{ ...modalStyle }}>
            <Typography variant="h5" sx={{mb:2}} mt={2}>Attributes</Typography>
                    <Grid container spacing={2}>
                    { attributesTag && <FormControl   sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="attruibute-select">Attributes</InputLabel>
                        <Grid xs={4} item >
                          <Select
                              sx={{ width: '200px' }}
                              size="small"
                              labelId="attribute-select"
                              id="attribute-select"
                              value={newAttribute}
                              label="Attributes"
                              onChange={handleAttributesChange}
                          >
                              {attributesTag}
                          </Select>
                        </Grid>
                        </FormControl > }
                        { showAttributeContext && <FormControl   sx={{ m: 1, minWidth: 120 }}>
                        <Grid xs={4} item>
                         <InputLabel id="attribute-context">Context</InputLabel>
                          <Select
                              sx={{ width: '200px' }}
                              size="small"
                              labelId="attribute-context"
                              id="attribute-context"
                              value={contextAttribute}
                              label="Context"
                              onChange={handleAttributeContextChange}
                          >
                              {attributeContextTag}
                          </Select> 
                        </Grid> 
                        </FormControl>}

                      </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "20px" }}
                        onClick={handleFormSubmit}
                    >
                        Submit
                    </Button>
                    <Button 
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "20px", marginLeft:'10px' }}
                      onClick={onClose}>Close</Button>
                </Grid>
      </Box>
    </Modal>
  );
};

export default AttributesModal;
