import React, {useState, useEffect} from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Modal, Button, Box, Typography, Grid } from "@mui/material";
import { modalStyle } from "../styles.js";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { removeIndexFromLabel } from '../Utils.js';

const RestoreAttributesModal = ({ extractedValuesOriginRef, extractedValues, isOpen, onClose, handleRestoreAttributeModalSubmit} ) => {
     const [attribute, setAttribute] = useState('');
     const [attributesTag, setAttributesTag] = useState(false);
  
  
  useEffect(() => {
    const attributes = provideAttributes();

    const tags = attributes.map((attribute) => {
        return <MenuItem key={attribute} value={attribute}>{attribute}</MenuItem>
    });
    
    setAttributesTag(tags);
  
  },[]);

  useEffect(() => {
    // Reset the dropdown value when the modal is closed
    if (!isOpen) {
      setAttribute('');
    }
  }, [isOpen]);


  const provideAttributes = () => {
    let diffKeys = [];

    const originKeys = Array.from(extractedValuesOriginRef.current.keys()).map( key => removeIndexFromLabel(key));
    const extractedkeys = Array.from(extractedValues.keys()).map( key => removeIndexFromLabel(key));
    
    diffKeys = originKeys.filter(key => !extractedkeys.includes(key));

    return [...new Set(diffKeys)].sort();
  };

  const handleFormSubmit = () => {
    handleRestoreAttributeModalSubmit(attribute);
  };

  const handleAttributesChange = (event) => {
    const attribute = event.target.value;
    setAttribute(attribute);
  }

  return (
    <Modal 
        open={isOpen} 
        onClose={onClose}
        aria-labelledby="List of Attributes"
        aria-describedby="List of Attributes"
        >
       <Box sx={{ ...modalStyle }}>
            <Typography variant="h5" sx={{mb:2}} mt={2}>Attributes</Typography>
            <Grid container spacing={2}>
            { attributesTag &&
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="attruibute-select">Attributes</InputLabel>
                    <Grid xs={4} item >
                        <Select
                            sx={{ width: '200px' }}
                            size="small"
                            labelId="attribute-select"
                            id="attribute-select"
                            value={attribute}
                            label="Attributes"
                            onChange={handleAttributesChange}
                        >
                            {attributesTag}
                        </Select>
                    </Grid>
                </FormControl >
            }

            </Grid>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px" }}
                    onClick={handleFormSubmit}
                >
                    Add
                </Button>
                <Button 
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "20px", marginLeft:'10px' }}
                    onClick={onClose}>Close</Button>
            </Grid>
      </Box>
    </Modal>
  );
};

export default RestoreAttributesModal;
