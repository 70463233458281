import React, { useState } from "react";
import { Modal, Box, Typography, Chip, Tooltip } from "@mui/material";
import { attributeValidationIssueModalStyle } from "../../styles";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";



const AttributeValidationIssueModal = ({
  isOpen,
  onClose,
  attribute,
  selectedFile,
  validationAttributeIssues
}) => {

  const [colDefs] = useState([
    {
      field: "rule_name",
      headerName: "Rule Name",
      width: 500,
      editable: false,
      cellStyle: { textAlign: "left" },
    },
    {
      field: "reason",
      headerName: "Reasons",
      width: 800,
      editable: false,
      cellStyle: { textAlign: "left" },
    },
  ]);


  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="Validation Issues"
      aria-describedby="Validation Issues"
    >
      <Box sx={{ ...attributeValidationIssueModalStyle }}>
        <Box
            sx={{
                display: "flex",
                gap: "10px",
                // justifyContent: "space-between",
                alignItems: "center",
                padding: 1,
                backgroundColor: "#eeeeee",
            }}
            >
            <Box sx={{ width: '20%', marginLeft: "10px"}}>
                <Typography>Validation Issues</Typography>
            </Box>
            <Chip label={selectedFile} variant="outlined"  sx={{padding: '0 5px', gap: '5px'}}/>
            <Chip label={attribute} variant="outlined"  sx={{padding: '0 5px', gap: '5px'}}/>
            <Box display="flex" justifyContent="flex-end" width="100%">
                <Tooltip title="Close">
                    <FontAwesomeIcon icon={faWindowClose} onClick={onClose} size="2x" />
                </Tooltip>
            </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "93%",
            display: "flex",
            // borderRadius: "5px",
            // border: "1px solid",
            flexDirection: "column",
            borderColor: "#babfc7",
            backgroundColor: "#eeeeee",
          }}
        >
          <AgGridReact
            rowData={validationAttributeIssues}
            columnDefs={colDefs}
            className={"ag-theme-quartz"}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default AttributeValidationIssueModal;
