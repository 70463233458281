import React from 'react';

const ConfirmationStatsPopup = ({ totalFiles, totalFilesConfirmed, totalFields, totalFieldsConfirmed }) => {

    const calculatePercentage = (count, total) => ((count / total) * 100).toFixed(2);


    return (
        <div className="confirmation-stats-popup">
            <h4>Confirmation Stats</h4>
            <table align='center' border="0" cellSpacing="5" cellPadding="3" style={{fontSize:"12px"}}>
                <tbody>
                <tr>
                    <th>Type</th>
                    <th>Total</th>
                    <th>Confirmed</th>
                    <th>% Confirmed</th>
                </tr>
                <tr>
                    <td>Files</td>
                    <td>{totalFiles}</td>
                    <td>{totalFilesConfirmed}</td>
                    <td>{calculatePercentage(totalFilesConfirmed, totalFiles)}%</td>
                </tr>
                <tr>
                    <td>Fields</td>
                    <td>{totalFields}</td>
                    <td>{totalFieldsConfirmed}</td>
                    <td>{calculatePercentage(totalFieldsConfirmed, totalFields)}%</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ConfirmationStatsPopup;