import gtConfig from './config.js';
import _ from 'lodash';

export function lodashDeepClone(obj){
    return _.cloneDeep(obj)
}


export function removeIndexFromLabel(label) {
    if (label === undefined)
        return label;
    return label.replace(/\[.+?]/, '');   
}

export function currentValueExistInContextValues(fileType, currentValue, updatedMap){
    const values =  getContextValues(fileType, updatedMap);
    return values.has(currentValue);
}

export function getContextValues(fileType, extractedValues){
    const contextAttribute = gtConfig.context_attribute[fileType];
    let values = new Set();
    if (!extractedValues)
        return values;

    const contextFields = gtConfig.context_attribute_fields;
    const keys = extractedValues.keys();
    for (let key of keys){
        let attribute = removeIndexFromLabel(key)
        if (attribute !== contextAttribute) {
            continue;
        }
        const fields = extractedValues.get(key)[0][0];
        for (let i = 1; i < extractedValues.get(key)[0].length; i++) {
            for (let contextField of contextFields){
                const fieldIndex = fields.indexOf(contextField);
                if (fieldIndex === -1 ){
                    continue;
                }
                let value = extractedValues.get(key)[0][i][fieldIndex][0];
                if (!value) {
                    continue
                } else if (Array.isArray(value)) {
                    const val = value.join();
                    if (val !== '_NF'){
                        values.add(val);
                    }
                } else {
                    if (value !== '_NF'){
                        values.add(value);
                    }
                    
                }
            }
        }
    }
    return values;
}

export function getSubContextValues(fileType, extractedValues){
    const subContextAttribute = gtConfig.subContext_attribute[fileType]
    let values = new Set();
    if (!extractedValues)
        return values;

    const keys = extractedValues.keys();
    for (let key of keys){
        let attribute = removeIndexFromLabel(key)
        if (attribute === subContextAttribute) {
            for (let i = 1; i < extractedValues.get(key)[0].length; i++) {
                let value = extractedValues.get(key)[0][i][0][0];
                if (!value) {
                    continue
                } else if (Array.isArray(value)) {
                    values.add(value.join());
                } else {
                    values.add(value);
                }
            }
        }
    }
    return values;
}

export function addMetadataToFinalResponse(operation, userId, extractedValues, meta){
    let finalExtractedValues = structuredClone(extractedValues);
    const dateTime =  new Date().toISOString().slice(0, 19)
    if ( operation === 'save'){
        finalExtractedValues.set('confirmedBy', meta.confirmedBy);
        finalExtractedValues.set('confirmedDate', meta.confirmedDate);

        finalExtractedValues.set('savedBy', userId);
        finalExtractedValues.set('savedDate', dateTime);
    }

    if ( operation === 'submit'){
        finalExtractedValues.set('savedBy', userId);
        finalExtractedValues.set('savedDate', dateTime);

        finalExtractedValues.set('confirmedBy', userId);
        finalExtractedValues.set('confirmedDate', dateTime);
    }

    return finalExtractedValues
}

export function deleteAllRedOcrDivs(redOcrDivRefs) {
    redOcrDivRefs.current.forEach(div => {
      if (div.parentNode) {
        div.parentNode.removeChild(div);
      }
    });
    redOcrDivRefs.current = [];
};


export function clearAllNoOcrDivs(noOcrDivRefs) {
    noOcrDivRefs.current.forEach((_, id) => {
        const divToRemove = document.getElementById(id);
        if (divToRemove) {
            divToRemove.remove();
        }
    });
    noOcrDivRefs.current = new Map();
};