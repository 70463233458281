import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Button, Snackbar, Typography} from "@mui/material";
import {DIV_IDS} from '../../constants';
import gtConfig from '../../config.js';
import MyGrid from './MyGrid.jsx';
import { loadingStyle, rightPanelButtonsStyle } from "../../styles";
import InputBoxMenuContext from './../../share/InputBoxMenuContext';
import { unlockDocument } from './PathFinder.js';
import {useTheme} from "@mui/material/styles";

//import UndoIcon from '@mui/icons-material/Undo';
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    findCellDivs,
    addNewRowToTable,
    deleteRowFromTable,
    getTableIndexes,
    isTable
} from './TableAttributesUtil.js'

import {
    getScheduleInitialValues,
} from './ScheduleInitialValues.js'

import {v4 as uuidv4} from "uuid";
import {addMetadataToFinalResponse, currentValueExistInContextValues, getContextValues, getSubContextValues, removeIndexFromLabel} from '../../Utils.js'
import {saveExtraction, submitExtraction} from './../../services';
import fetchDataFileUrl from "./../../services";
import { useOktaAuth } from '@okta/okta-react';
import AttributesPopupModal from '../AttributesPopupModal.jsx';
import SelectComponent from './SelectComponent.jsx';
import GridTotalsHeader from './GridTotalsHeader.jsx';
import { addAttributeKeyToChangedAttributeRef, deleteAttributeKeyFromChangedAttributeRef, ifAttributeKeyIncludeInChangedAttribute, ifAttributeKeyIncludeInChangedAttributeItem, revertAllAttributeChanges, revertAttributeChanges } from './ChangedAttribute.js';
import { dataAreValidToConfirm, ifAllRequiredAttributesAreConfirmed, isTableValidToBeConfirm, removeInvalidAttributes } from './ConfirmAttributes.js';
import AttributeValidationIssueModal from './AttributeValidationIssueModal.jsx';
import ConfirmAllDialog from './ConfirmAllDialog.jsx';


const GT_OPS_CONFIRMED_CATEGORY = 'gt_ops_confirmed'
const EXTRACTION_CATEGORY = 'extraction'


const ExtractionConfirmContent = ({
                                      selectedFile,
                                      extractedValuesOriginRef,
                                      extractedValuesMain,
                                      handleAddNewAttribute,
                                      handleRestoreAttribute,
                                      onKeySelect,
                                      updateExtractedValues,
                                      category,
                                      file_type,
                                      handleClearInput,
                                      isLoading,
                                      headerHeight,
                                      changedAttributesRef,
                                      editMode,
                                      setEditMode,
                                      showSnackBar,
                                      setTaskOwner,
                                      confirmedBy,
                                      confirmedDate,
                                      savedBy,
                                      savedDate,
                                      isPathFinderEnable,
                                      validationIssues
                                  }) => {
    
    const { authState, oktaAuth } = useOktaAuth();
    const [selectedKey, setSelectedKey] = useState(null);
    const [confirmedKeys, setConfirmedKeys] = useState(new Set());
    const [allConfirmed, setAllConfirmed] = useState(true);
    const [extractedValues, setExtractedValues] = useState(extractedValuesMain);
    const [attributeName, setAttributeName] = useState('');

    const [saveOk, setSaveOk] = useState(false);
    const [contextValues, setContextValues] = useState( new Set());
    const [subContextValues, setSubContextValues] = useState( new Set());
    const [validationAttributeIssues, setValidationAttributeIssues] = useState([]);

    const [confirmAllDialogOpen, setConfirmAllDialogOpen] = useState(false);
    const theme = useTheme();
    
    useEffect(() => {
        const contexts = getContextValues(file_type, extractedValues);
        setContextValues(contexts);
    
        const subContexts = getSubContextValues(file_type, extractedValues);
        setSubContextValues(subContexts)
    
    }, [extractedValues]);

       
    const [isAttributesPopupModalOpen, setIsAttributesPopupModalOpen] = useState(false);
    const handleAttributesPopupOpenModal = useCallback(() => {
        setIsAttributesPopupModalOpen(true);
    }, []);
    const handleAttributesPopupCloseModal = useCallback(() => {
        setIsAttributesPopupModalOpen(false);
    }, []);

    const [isAttributeValidationIssueModalOpen, setIsAttributeValidationIssueModalOpen] = useState(false);
    const handleAttributeValidationIssueOpenModal = useCallback(() => {
        setIsAttributeValidationIssueModalOpen(true);
    }, []);
    const handleAttributeValidationIssueCloseModal = useCallback(() => {
        setIsAttributeValidationIssueModalOpen(false);
    }, []);


    useEffect(() => {
        setConfirmedKeys(new Set());
    }, [selectedFile]);
	
    useEffect(() => {
        setExtractedValues(extractedValuesMain);
    }, [selectedFile, extractedValuesMain]); // Include selectedFile as a dependency
    
    const handleKeySelect = (e, key) => {
        onKeySelect(key);
    };

    // Empty onChange handler to prevent warning since we are using input inside form
    const handleInputChange = () => {
    };

    const openPopup = (key) => {
        setAttributeName(key);
        handleAttributesPopupOpenModal();
        setSelectedKey(key);
    };

    const showValidationIssueIcon = (key) => {
        if (!validationIssues){
            return false;
        }

        // Checking both attribute name starting with and without '_' since validation issue report provide all attributes without '_'
        const attribute = removeIndexFromLabel(key);
        const underlineAttribute = attribute.startsWith('_')  ? attribute.substring(1) : `_${attribute}`;
        
        return validationIssues.hasOwnProperty(attribute) || validationIssues.hasOwnProperty(underlineAttribute)
    }

    const showAttributeValidationIssues = (key) => {
        let attribute = removeIndexFromLabel(key);
        
        // Checking both attribute name starting with and without '_' since validation issue report provide all attributes without '_'
        const underlineAttribute = attribute.startsWith('_')  ? attribute.substring(1) : `_${attribute}`;        
        const valAttribute = validationIssues[attribute]
        const valUnderlineAttribute = validationIssues[underlineAttribute]
        let value = null;
        if (valAttribute){
            value = valAttribute;
        } else if (valUnderlineAttribute){
            value = valUnderlineAttribute;
        }    
                
        setValidationAttributeIssues(value);
        setAttributeName(key);
        handleAttributeValidationIssueOpenModal();
    }

    const isRestoreAttributeBtnDisabled = () => {
        const originKeys = Array.from(extractedValuesOriginRef.current.keys());
        const extractionKeys = Array.from(extractedValues.keys());
        for ( let key of originKeys){
            if ( !extractionKeys.includes(key)){
                return false
            }
        }
        
        return true;
    }

    const closePopup = () => {
        setSelectedKey(null);
        // Implement logic to close the pop-up window here
    };

    const getInputId = (key, type) => {
        return type + "_" + key;
    };


    const disableSubmitButton = () => {
        return  !ifAllRequiredAttributesAreConfirmed(extractedValues, confirmedKeys) || !allConfirmed
    };

    const handleSaveChanges = () => {
        const allKeysExist = ifAllRequiredAttributesAreConfirmed(extractedValues, confirmedKeys);
        if (allKeysExist) {
            const saveChanges = async (extractedValues, authState, oktaAuth,  selectedFile, file_type, url) => {
                setAllConfirmed(true);
                try{
                    const email = authState.idToken.claims.email;
                    const userId = email.replace('@spglobal.com', '');
                    const  finalExtractedValues = addMetadataToFinalResponse('save', userId, extractedValues,
                         {'confirmedBy': confirmedBy, 'confirmedDate': confirmedDate});
                    await saveExtraction(finalExtractedValues, authState, oktaAuth,  selectedFile, file_type, url);
                    setSaveOk(true);
                    setTimeout(() => {
                        setSaveOk(false);
                    }, 2000);
                } catch (error) {
                    console.error('Error Saving Changes:', error);
                }
            }
            const url = gtConfig.ground_truth_back_end.saveUrl;
            saveChanges(extractedValues, authState, oktaAuth,  selectedFile, file_type, url);

        } else {
            setAllConfirmed(false);
            setTimeout(() => {
                setAllConfirmed(true);
            }, 2000);
        }
    };

    const handleSubmitChanges = () => {
        const allKeysExist = ifAllRequiredAttributesAreConfirmed(extractedValues, confirmedKeys);
        const email = authState.idToken.claims.email;
        const userId = email.replace('@spglobal.com', '');
        if (allKeysExist) {
            const submitChanges = async (extractedValues, authState, oktaAuth,  selectedFile, file_type, url) => {
                setAllConfirmed(true);
                try{
                    const  finalExtractedValues = addMetadataToFinalResponse('submit', userId, extractedValues,
                    {'savedBy': savedBy, 'savedDate': savedDate});
                
                    const result = await submitExtraction(finalExtractedValues, authState, oktaAuth,  selectedFile, file_type, url);
                   if (result && result.success){
                        setTaskOwner('');
                        showSnackBar('Document Submitted Successfully', 'success');
                   } else if (result && !result.success){ // Error in Pathfinder
                        setTaskOwner('');
                        showSnackBar(result.message, 'error');
                   }
                   if (isPathFinderEnable){
                    setEditMode(false);
                   }
                } catch (error) {
                    console.error('Error Saving Changes:', error);
                }
            }
            const url = gtConfig.ground_truth_back_end.submitUrl + "?userId=" + userId;
            submitChanges(extractedValues, authState, oktaAuth,  selectedFile, file_type, url);
        } else {
            setAllConfirmed(false);
            setTimeout(() => {
                setAllConfirmed(true);
            }, 2000);
        }
    };


    const isUserEligibleToSubmit = () => {
        const email = authState.idToken.claims.email;
        const userId = email.replace('@spglobal.com', '');
        if (process.env.REACT_APP_ENV === 'development'){
            return true;
        }

        if (process.env.REACT_APP_ENV === 'qa' && gtConfig.qa_submit_authorized_users.includes(userId)) {
            return true;
        }

        if (process.env.REACT_APP_ENV === 'production' && gtConfig.prod_submit_authorized_users.includes(userId)) {
            return true;
        }

        return false;
    }

    const handleConfirmAll = () => {
        if (!dataAreValidToConfirm(extractedValues)) {
            showSnackBar(`Please remove empty schedule rows to confirm Document.` , 'warning');
            return;
        }
        setConfirmAllDialogOpen(true);
    };

    const acceptConfirmAll = () => {
        let extractedValuesCloned = structuredClone(extractedValues);
        extractedValuesCloned = removeInvalidAttributes(extractedValuesCloned);
      
        
        updateExtractedValues(extractedValuesCloned);
        setExtractedValues(extractedValuesCloned);

        const keysToConfirm = Array.from(extractedValuesCloned)
        .filter(
            ([key, value]) =>
                (!gtConfig.attributes_to_exclude.includes(key) && !gtConfig.attributes_to_exclude.some(att => key.startsWith(att + '[')))
        )
        .map(([key, value]) => key);

        keysToConfirm.forEach((key) => {
            setConfirmedKeys((prevSet) => new Set([...prevSet, key]));
        });
        onKeySelect(null);
        setConfirmAllDialogOpen(false);
    }


    const handleUnconfirmAll = () => {
        setConfirmedKeys(new Set());
        onKeySelect(null);
    };

    const handleConfirm = (key) => {
        if (isConfirmed(key)) {
            const modifiedSet = new Set([...confirmedKeys].filter(item => item !== key));
            setConfirmedKeys((prevSet) => new Set(modifiedSet));
            onKeySelect(null);
        } else {
            // setConfirmedKeys([...confirmedKeys, key]);
            const data = extractedValues.get(key);
            if (Array.isArray(data) && Array.isArray(data[0])) { // Check if we are confirming a complete table
                if (!isTableValidToBeConfirm(data[0])) {
                    showSnackBar(`Remove empty rows to confirm ${removeIndexFromLabel(key)}` , 'warning');
                    return;
                }
            } else {
                if ( Array.isArray(data) && data.length > 0 && (!data || gtConfig.attribute_empty_values.includes(data[0])) ){
                    showSnackBar(`You could not confirm empty value for ${removeIndexFromLabel(key)}` , 'warning');
                    return;
                }
            }
            setConfirmedKeys((prevSet) => new Set([...prevSet, key]));
            onKeySelect(null);
        }
    };

    const isConfirmed = (key) => {
        return confirmedKeys.has(key) ? true : false
    }

    const mainDivClass = (key) => {
        return `attribute-div ${( confirmedKeys.has(key) || !editMode) ? "disabled" : ""}`;
    };

    const attributeOpoupClass =(key) => {
        return `attribute-popup ${( confirmedKeys.has(key) || !editMode) ? "disabled" : ""}`;
    };


    const mainDivConfirmedClass = (key) => {
        return `attribute-div ${( confirmedKeys.has(key)) ? "disabled" : ""}`;
    };

    const handleConfirmTableRow = (key, data) => {
        if (isConfirmed(key)) {
            const modifiedSet = new Set([...confirmedKeys].filter(item => item !== key));
            setConfirmedKeys((prevSet) => new Set(modifiedSet));
            onKeySelect(null);
        } else {
            const size = data.length;
            for (let i = 0; i < size - 1; i++) {   // Don't confirm empty rows - Ignore last element since it contains index and always has value
                if (data[i][0] && data[i][0] !== '') {
                    setConfirmedKeys((prevSet) => new Set([...prevSet, key]));
                    onKeySelect(null);
                    return;
                }
            }
            showSnackBar(`You could not confirm empty row.` , 'warning');
            return;
        }

    };


    const handleClearInputValue = (attributeKey) => {
        const newMap = new Map(extractedValues);
        const divIds = getDivIds(newMap.get(attributeKey));
        let attribute = newMap.get(attributeKey)
        attribute[0] = '';
        changeStyleOfHoveredElement(attributeKey, divIds, false);
        deleteDivIds(attribute);
        updateExtractedValues(newMap);
        setExtractedValues(newMap);
        handleClearInput();

        addAttributeKeyToChangedAttributeRef(attributeKey, changedAttributesRef.current);
    }

    const isRevertAllAttributeDisabled = (selectedKey) => {
        return !ifAttributeKeyIncludeInChangedAttribute(selectedKey, changedAttributesRef.current);
    }

    const isRevertAttributeDisabled = (selectedKey) => {
        return !extractedValuesOriginRef.current.has(selectedKey) ||
            ifAttributeKeyIncludeInChangedAttributeItem(selectedKey, changedAttributesRef.current) || !editMode;
        
    }

    const handleSelectChange = (e, attributeKey) => {
        const newMap = new Map(extractedValues);
        let attribute = newMap.get(attributeKey);
        attribute[0] = e.target.value;

        addAttributeKeyToChangedAttributeRef(attributeKey, changedAttributesRef.current);

        updateExtractedValues(newMap);
        setExtractedValues(newMap);
    }

    const deleteDivIds = (attribute) => {
        if (Array.isArray(attribute)) {
            const divIdsObject = attribute.find(item => item && typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, DIV_IDS));
            if (divIdsObject) {
                divIdsObject[DIV_IDS] = [];
            }
        }
        return null;
    }

    const removeConfirmedItemofDeletingTable = (key, tableData) => {
        const tableIndexes = getTableIndexes(tableData);
        const modifiedSet = new Set([...confirmedKeys].filter(item => !tableIndexes.includes(item)));
        setConfirmedKeys((prevSet) => new Set(modifiedSet));

    }

    const handleMouseOverTableCellEvent = (cellId, rowIndex, tableId) => {
        const divIds = findCellDivs(extractedValues, cellId, rowIndex, tableId);
        changeStyleOfHoveredElement(cellId, divIds, true);
    }


    const handleMouseOutTableCellEvent = (cellId, rowIndex, tableId) => {
        const divIds = findCellDivs(extractedValues, cellId, rowIndex, tableId);
        changeStyleOfHoveredElement(cellId, divIds, false);

    }

    const handleMouseOverEvent = (key) => {
        const divIds = getDivIds(extractedValues.get(key));
        changeStyleOfHoveredElement(key, divIds, true);
    };

    const handleMouseOutEvent = (key) => {
        const divIds = getDivIds(extractedValues.get(key));
        changeStyleOfHoveredElement(key, divIds, false);
    };

    const changeStyleOfHoveredElement = (key, divIds, status) => {
        const styleValue = status ? "5px solid blue" : "";
        //const divIds = getDivIds(extractedValues.get(key));
        if (divIds != null) {
            for (let divId of divIds) {
                const pdfOverlayElement = document.getElementById(divId);
                if (pdfOverlayElement) {
                    pdfOverlayElement.style.borderBottom = styleValue;
                }
            }
        } else {
            changeStyleOfHoveredElementUsingOriginDivId(key, styleValue, status);
        }
    }

    const changeStyleOfHoveredElementUsingOriginDivId = (key, styleValue, status) => {
        const pdfOverlayElement = document.getElementById(key + "_overlay");
        if (pdfOverlayElement) {
            pdfOverlayElement.style.borderBottom = styleValue;
            if (status){
                pdfOverlayElement.style.zIndex = 1;
            } else {
                pdfOverlayElement.style.zIndex = -1;
            }
        }
    }

    const getDivIds = (content) => {
        if (Array.isArray(content)) {
            const divIdsObject = content.find(item => item && typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, DIV_IDS));
            if (divIdsObject) {
                return divIdsObject[DIV_IDS];
            }
        }
        return null;
    }

    const handleTableCellClickEvent = (cellId, rowIndex, tableId) => {
        const divIds = findCellDivs(extractedValues, cellId, rowIndex, tableId);
        scrollToSelectedDivs(cellId, divIds);
    }


    const handleClickPageEvent = (key) => {
        const divIds = getDivIds(extractedValues.get(key));
        scrollToSelectedDivs(key, divIds);
    }

    const scrollToSelectedDivs = (key, divIds) => {
        /*
        * If divIds had value scroll to the selected divIds.
        * otherwise scroll to key + "_overlay" if it was available.
        */
        let pdfOverlayElement = null;
        if (divIds != null && divIds.length > 0) {
            pdfOverlayElement = pdfOverlayElement = document.getElementById(divIds[0]);
        } else {
            pdfOverlayElement = document.getElementById(key + "_overlay");
        }

        if (pdfOverlayElement) {
            pdfOverlayElement.scrollIntoView({block: "start", inline: "nearest"}); // behavior: 'smooth', smooth behavior not working for long scrolls
            setTimeout(() => {
                //adjust scroll down 20px so it isn't at very top
                const padding = 40  + parseInt(headerHeight);
                window.scrollBy(0, -padding);
            }, 100);
            //pdfOverlayElement.style.borderBottom = '5px solid blue';
            //console.log('scroll2', window.scrollY);
        }
    };

    const isHeader = (index, extractedValues) => {
        if (!extractedValues) return;
        if (index === 0) {
            return true
        }

        let keysArray = Array.from(extractedValues.keys());
        const currentKey = keysArray[index];
        const previousKey = keysArray[index - 1];
        if (removeIndexFromLabel(currentKey) === removeIndexFromLabel(previousKey)) {
            return false
        }
        return true;
    }

    const provideAttributePopupLabel = (key) => {
        const keyArray = [...extractedValues].filter(([mapKey, value]) => removeIndexFromLabel(mapKey) === removeIndexFromLabel(key));
        const index = keyArray.findIndex((item) => item[0] === key) + 1;
        return (index).toString().padStart(2, '0');
    }

    const onContextChange = (e, attributeKey, attribute) => {
        const index = attribute === 'context' ? 9 : 10;
        const newMap = new Map(extractedValues);
        let attributeItem = newMap.get(attributeKey);
        
        if (Array.isArray(attributeItem) && attributeItem.length >= index){
            addAttributeKeyToChangedAttributeRef(attributeKey, changedAttributesRef.current);

            attributeItem[index] = e.target.value;
            updateExtractedValues(newMap);
            setExtractedValues(newMap);
        }
    }

    const handleClearGridInputBox = (extractionKey, cellIndex, rowIndex) => {
        const newMap = structuredClone(extractedValues);
        newMap.get(extractionKey)[0][rowIndex+1][cellIndex][0] = '';

        addAttributeKeyToChangedAttributeRef(extractionKey, changedAttributesRef.current);
        
        updateExtractedValues(newMap);
    }

    const updateGridValues = (newValues, extractionKey) => {
        const newMap = structuredClone(extractedValues);
        newMap.get(extractionKey)[0] = newValues;
        
        updateExtractedValues(newMap);
    }

    const handleDeleteTableRow = (key, extractionKey, deletedRow) => {
        const extractionValue = extractedValues.get(extractionKey);
        if (extractionValue[0].length === 2) { // It means we are deleting last row of the table ( length 2 mean header + deleting row), so we should delete table instead of row
            handleDeleteItem(extractionKey, deletedRow);
        } else {
            let newMap = deleteRowFromTable(extractedValues, extractionKey, key);
            
            const deletedAttribute = removeIndexFromLabel(extractionKey);
            if ( deletedAttribute === gtConfig.context_attribute[file_type]){
                updateContextsWhenAContextIsDeleted(newMap, deletedRow);
            }
            if ( deletedAttribute === gtConfig.subContext_attribute[file_type]){
                updateSubContextsWhenASubContextIsDeleted(newMap, deletedRow);
            }

            addAttributeKeyToChangedAttributeRef(extractionKey, changedAttributesRef.current);
            
            updateExtractedValues(newMap);
        }
    };

    const disableDeleteItemBtn = (key) => {
        const attribute = removeIndexFromLabel(key);
        return ( attribute === gtConfig.context_attribute[file_type] || attribute === gtConfig.subContext_attribute[file_type]);
    }

    const handleDeleteItem = (key, deletedRow) => {
        const newMap = new Map(extractedValues);
        const divIds = getDivIds(extractedValues.get(key));
        changeStyleOfHoveredElement(key, divIds, false);
        newMap.delete(key);
        
        const deletedAttribute = removeIndexFromLabel(key);
        if ( deletedAttribute === gtConfig.context_attribute[file_type]){
            updateContextsWhenAContextIsDeleted(newMap, deletedRow);
        }
        if ( deletedAttribute === gtConfig.subContext_attribute[file_type]){
            updateSubContextsWhenASubContextIsDeleted(newMap, deletedRow);
        }
        updateExtractedValues(newMap);
        setExtractedValues(newMap);


       if (isRemoveAllItemAttribute(deletedAttribute, newMap)){
            deleteAttributeKeyFromChangedAttributeRef(deletedAttribute, changedAttributesRef.current);
        } else {
            addAttributeKeyToChangedAttributeRef(key, changedAttributesRef.current);
        }
        
        const data = extractedValues.get(key);
        if (data !== undefined && isTable(data)) {
            removeConfirmedItemofDeletingTable(key, data);
        }
    };

    const isRemoveAllItemAttribute = (deletedAttribute, newMap) =>{
        for (let key of newMap.keys()) {
            if (key.startsWith(deletedAttribute)) {
                return false;
            }
        }
        return true;
    } 

    
    const updateContextsWhenAContextIsDeleted = (newMap, deletedRow) => {
        const contextFields = gtConfig.context_attribute_fields;
        let deletedValues = []
        for (let item of deletedRow){
            if (item.length > 1 && contextFields.includes(removeIndexFromLabel(item[1])) ){
                deletedValues.push(item[0]);
            }
        }

        for ( let deletedValue of deletedValues){
            if (Array.isArray(deletedValue)){
                deletedValue = deletedValue.join('');
            }

            const valueExist = currentValueExistInContextValues(file_type, deletedValue, newMap);
            if (valueExist){
                continue;
            }

            const indexOfContext = 9;
            for (let attribute of newMap ){
                if (Array.isArray(attribute) && attribute.length >= 1 && attribute[1].length >= indexOfContext && attribute[1][indexOfContext] === deletedValue){
                    attribute[1][indexOfContext] = '_NF'
                } 
            }
        }
        return newMap;
    }

    const updateSubContextsWhenASubContextIsDeleted = (newMap, deletedRow) => {
        let deletedValue = deletedRow[0][0];
        if (Array.isArray(deletedValue)){
            deletedValue = deletedValue.join('');
        }

        const valueExist = currentValueExistInContextValues(file_type, deletedValue, newMap);
        if (valueExist){
            return;
        }

        const indexOfSubContext = 10;
        for (let attribute of newMap ){
            if (Array.isArray(attribute) && attribute.length >= 1 && attribute[1].length >= indexOfSubContext && attribute[1][indexOfSubContext] === deletedValue){
                attribute[1][indexOfSubContext] = '_NF'
                } 
        }
        return newMap;
    }

    const handleAddNewRowToTable = (key) => {
        const newMap = addNewRowToTable(extractedValues, key);

        addAttributeKeyToChangedAttributeRef(key, changedAttributesRef.current);

        updateExtractedValues(newMap);
    }
    
    const handleRevertAllSeriesAttribute = (selectedKey) => {        
        const attribute = removeIndexFromLabel(selectedKey);
        const newMap = new Map();
        for (let key of extractedValuesOriginRef.current.keys()){
            if (key.startsWith(attribute)){
                newMap.set(key, structuredClone(extractedValuesOriginRef.current.get(key)));
            } else {
                if (extractedValues.get(key)){ //Don't add deleted attributes
                    newMap.set(key, structuredClone(extractedValues.get(key)));
                }
            }
        }

        changedAttributesRef.current.delete(selectedKey);
        revertAllAttributeChanges(selectedKey, changedAttributesRef.current, confirmedKeys, extractedValuesOriginRef.current);

        updateExtractedValues(newMap);
        setExtractedValues(newMap);
    }

    const handleRevertSelectedSeriesAttribute = (selectedKey) => {     
        const newMap = new Map(extractedValues);
        if (extractedValuesOriginRef.current.has(selectedKey)){
            newMap.set(selectedKey, structuredClone(extractedValuesOriginRef.current.get(selectedKey)));
       
            revertAttributeChanges(selectedKey, changedAttributesRef.current, confirmedKeys, extractedValuesOriginRef.current);
            
            updateExtractedValues(newMap);
            setExtractedValues(newMap);   
        }
    }

    const handleAddNewAttributeRow = (keyToInsert) => {
        const scheduleName = removeIndexFromLabel(keyToInsert)
        const newMap = new Map();
        const uniqueId = uuidv4();
        let new_key = keyToInsert.replace(/\[.*]/, `[${uniqueId}]`);
        if ( gtConfig.schedules.includes(scheduleName)) {
            let attributeContext = ''
            extractedValues.forEach((item, key) => {
                if (key === keyToInsert && Array.isArray(item) && item.length > 8) {
                    //Currenly context is located in index = 9
                    attributeContext = item[9];
                }
            });

            const newScheduleInitialValue = getScheduleInitialValues(file_type, scheduleName, attributeContext);
            if (newScheduleInitialValue == null){
                console.log('Schedule Initial value does not exist');
            } else {
                extractedValues.forEach((value, key) => {
                    if (key === keyToInsert) {
                        const uniqueId = uuidv4();
                        let new_key = keyToInsert.replace(/\[.*]/, `[${uniqueId}]`);
                        newMap.set(new_key, newScheduleInitialValue);
                    }
                   newMap.set(key, value);
                });
            }
        } else {
            extractedValues.forEach((value, key) => {
                if (key === keyToInsert) {
                    let attributeContext = "";
                    //Currenly context is located in index = 9
                    if ( value != null && Array.isArray(value) && value.length > 8){
                        attributeContext = value[9];
                    }
                    newMap.set(new_key, ["Not Found", "", "", "", "NA", "","", "","", attributeContext ]);
                }
                newMap.set(key, value);
            });
        }

        if (newMap.size > 0){
            addAttributeKeyToChangedAttributeRef(new_key, changedAttributesRef.current);
            updateExtractedValues(newMap);
            setExtractedValues(newMap);
        }
    };

    const showAttributeInDropDownFormat = (file_type, key) => {
        const dropDownFormatFileTypes = gtConfig.dropDownFormatFileTypes;
        const dropDownFormatKeys = gtConfig.dropDownFormatKeys[file_type];
        const attributeName = removeIndexFromLabel(key);

        return dropDownFormatFileTypes.includes(file_type) && dropDownFormatKeys.includes(attributeName);
    };

    function hasAggregation(key){
        const scheduleName = key.substring(0, key.indexOf('['))
        const aggregationFields = gtConfig.aggregation[file_type];
        if (aggregationFields !== undefined) {
            return aggregationFields[scheduleName] !== undefined;
        }
    }

    function disableContext(key){
        const showcontextFileTypes = gtConfig.show_context_file_types;
        return !showcontextFileTypes.includes(file_type);
    }

    function disableSubContext(key){
        const attribute = removeIndexFromLabel(key);
        const showSubcontextFileTypes = gtConfig.show_subcontext_file_types;
        const showSubcontextAttributes = gtConfig.show_subcontext_attributes[file_type];

        return !showSubcontextFileTypes.includes(file_type)  || !showSubcontextAttributes.includes(attribute);
     }

    return (
        <>
            <ConfirmAllDialog
                acceptConfirmAll={acceptConfirmAll}
                confirmAllDialogOpen={confirmAllDialogOpen}
                setConfirmAllDialogOpen={setConfirmAllDialogOpen}
            />
            <h3>
                {saveOk && (
                    <div className="overlay">
                        <p>Saved, Thanks!</p>
                    </div>
                )}

                {!allConfirmed && (
                    <div className="overlay-red">
                        <p>Please confirm ALL Values</p>
                    </div>
                )}
            </h3>
            {extractedValues && (
                <form style={ isLoading ? {...loadingStyle} : undefined}>
                    {Array.from(extractedValues).map(([key, value], index) => (
                        <div  key={key} style={{marginBottom: "10px"}}>
                            {isHeader(index, extractedValues) && (
                                <div>
                                    &nbsp;
                                    <b>
                                    {showValidationIssueIcon(key) &&
                                    <u>
                                       <IconButton
                                           aria-describedby="view-validation-issues"
                                           aria-label="View Validation Issues"
                                           title="Validation Issues"
                                           size='small'
                                           color="warning"
                                           onClick={(e) => showAttributeValidationIssues(key)}
                                           >
                                           <FontAwesomeIcon icon={faExclamationCircle} size={"xs"} />
                                       </IconButton>
                                    </u>
                                    }
                                    {/* &nbsp; */}
                                    <u>
                                        <Button
                                            variant="text"
                                            onClick={() => openPopup(key)}
                                            style={{ textTransform: 'none', padding: 0 }}
                                            className={attributeOpoupClass(key)}
                                            size="small"
                                        >
                                            {removeIndexFromLabel(key)}
                                        </Button>
                                    </u>

                                    </b>
                                    &nbsp;
                                    &nbsp;
                                    <div style={{ display: 'flex'}}>
                                        <button type="button"
                                            disabled={ifAllRequiredAttributesAreConfirmed(extractedValues, confirmedKeys) || !editMode}
                                            style={{
                                                height: '12.5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                            title='Add new value'
                                            onClick={() => handleAddNewAttributeRow(key)}><label>+</label>
                                        </button>
                                        <button
                                            type="button"
                                            disabled={isRevertAllAttributeDisabled(key) || !editMode}
                                            style={{
                                                height: '12.5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                            title='Revert attribute to original values'
                                                onClick={() => handleRevertAllSeriesAttribute(key)}><label>⤺</label>
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div
                                id={key}
                                onMouseOver={() => handleMouseOverEvent(key)}
                                onMouseOut={() => handleMouseOutEvent(key)}
                                style={{marginLeft: key.endsWith("]") ? "20px" : "0"}}
                            >
                            <Typography variant='caption' sx={{ color: theme.palette.text.primary}}>
                                <label>
                                    <b>
                                        {provideAttributePopupLabel(key)}
                                    </b>
                                    :&nbsp;&nbsp;
                                </label>

                                Conf: {(value[3] * 100).toFixed(0)}% &nbsp;|&nbsp; Page:    <a  onClick={() => handleClickPageEvent (key)}>
                                                                                                {value[4]}
                                                                                            </a>
                                &nbsp;|&nbsp; Corrob:{" "}
                                {value[2] === 1
                                    ? "pass"
                                    : (value[2] === 0 && value[1] === "") ||
                                    value[2] === undefined
                                        ? "n/a"
                                        : ''}
                                &nbsp;|&nbsp; Context: <b> { removeIndexFromLabel(key) !== gtConfig.context_attribute[file_type] &&
                                                                    <select id='context'
                                                                        style={{width:"100px",  fontSize:'12px', color: (Array.from((contextValues)).includes(value[9]) || value[9] == "_NF" || value[9] == null || Array.from((contextValues)).length == 0) ? 'black' : 'red'}}
                                                                        value={value[9]}
                                                                        onChange={(e) => onContextChange(e, key, 'context' )}
                                                                        title={value[9]}
                                                                        disabled={isConfirmed(key) || !editMode || disableContext(key)}
                                                                     >
                                                                        <option key='_NF' value='_NF'>_NF</option>
                                                                        {Array.from((contextValues)).map((val) => {
                                                                            return <option key={val}  value={val}>{val}</option>
                                                                        })}
                                                                    </select>}</b>
                                <b> { removeIndexFromLabel(key) !== gtConfig.context_attribute[file_type] && removeIndexFromLabel(key) !== gtConfig.subContext_attribute[file_type] &&
                                                                    <select id='context'
                                                                        style={{width:"85px",  fontSize:'12px', color: (Array.from((subContextValues)).includes(value[10]) || value[10] == "_NF" || value[10] == null || Array.from((subContextValues)).length == 0) ? 'black' : 'red'}}
                                                                        value={value[10]}
                                                                        onChange={(e) => onContextChange(e, key, 'subContext')}
                                                                        title={value[10]}
                                                                        disabled={isConfirmed(key) || !editMode || disableSubContext(key)}
                                                                     >
                                                                        <option key='_NF' value='_NF'>_NF</option>
                                                                        {Array.from((subContextValues)).map((val) => {
                                                                            return <option key={val}  value={val}>{val}</option>
                                                                        })}
                                                                    </select>}</b>
                                {/*&nbsp;|&nbsp; <u>Header</u>: {value[10]}*/}
                                <br/>
                                </Typography>
                                <button type="button" title='Confirm if there is not any empty row'
                                    disabled={!editMode}
                                        onClick={() => handleConfirm(key)}> {isConfirmed(key) ? <label>↺</label> :
                                    <label>Y</label>}
                                </button>
                                &nbsp;
                                <button
                                    disabled={disableDeleteItemBtn(key) || !editMode || isConfirmed(key)}
                                    type="button" 
                                    className={mainDivClass(key)}
                                    title='Delete attribute'
                                    onClick={() => handleDeleteItem(key)}><label>X</label>
                                </button>
    
                                &nbsp;
                                <button type="button"
                                    disabled={isRevertAttributeDisabled(key)}
                                    style={{
                                        height: '20px',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                
                                    className={mainDivClass(key)}  title='Revert selected attribute to original values'
                                        onClick={() => handleRevertSelectedSeriesAttribute(key)}><label>⤺</label>
                                </button>
              
                                &nbsp;
                                <span style={{
                                                color:
                                                    value.length === 2 ? '#FFBF00' :
                                                        value[2] === 1 ? 'green' :
                                                            value[2] === -1 ? 'red' :
                                                            ((value[2] === 0 && value[1] === '') || value[2] === undefined) ? 'black' :
                                                                'red'
                                            }}>{Array.isArray(value[0]) ? (value[0].length - 1).toString() + " items" : ""} 
                                             {key && hasAggregation(key) && 
                                               <GridTotalsHeader extractionKey={key} fileType={file_type} data={value[0]}/>}
                                            </span>
                                    { showAttributeInDropDownFormat(file_type, key)?  // Show attribute in DropDown format
                                    <SelectComponent id={getInputId(key, 'select')} extractionKey={key}
                                        attribute="value"
                                        mainDivClass={mainDivClass}
                                        fileType={file_type}
                                        initialData={value[0]}
                                        onSelectChange={(e) => handleSelectChange(e, key)}
                                        selectOptionStyle={{outline: 'none', padding: '1px',  maxWidth:'98px', minWidth:'98px'}}
                                        editMode={editMode}
                                        isConfirmed={isConfirmed}
                                    />
                                : ( // Show attribute in Grid/Table format
                                    Array.isArray(value[0]) ?
                                        <div className={mainDivConfirmedClass(key)} style={{ maxHeight: '600px', overflow:'auto' }}>
                                        
                                            <MyGrid initialData={value[0]} extractionKey={key} id={getInputId(key, 'text')}
                                                    handleMouseOverEvent={handleMouseOverTableCellEvent}
                                                    handleMouseOutEvent={handleMouseOutTableCellEvent}
                                                    handleClickEvent={handleTableCellClickEvent}
                                                    handleConfirmTableRow={handleConfirmTableRow}
                                                    handleDeleteTableRow={handleDeleteTableRow}
                                                    mainDivClass={mainDivClass}
                                                    handleKeySelect={handleKeySelect}
                                                    handleAddNewRowToTable={handleAddNewRowToTable}
                                                    isConfirmed={isConfirmed}
                                                    editMode={editMode}
                                                    fileType={file_type}
                                                    handleClearGridInputBox={handleClearGridInputBox}
                                                    updateGridValues={(newValues) => updateGridValues(newValues, key)}
                                                    changedAttributesRef={changedAttributesRef}
                                            />
                                </div> : // Show attribue in InputBox (InputBoxMenuContext) format
                                    <InputBoxMenuContext
                                            inputBoxId={getInputId(key, 'text')}
                                            inputBoxKey={key}
                                            inputBoxClassName={mainDivClass(key)}
                                            inputBoxType='text'
                                            inputBoxValue={value[0]}
                                            inputBoxSize="60"
                                            inputBoxStyle={{
                                                color:
                                                    value.length === 2 ? '#FFBF00' :
                                                        value[2] === 1 ? 'green' :
                                                            ((value[2] === 0 && value[1] === '') || value[2] === undefined) ? 'black' :
                                                                'red'
                                            }}
                                            mouseDownHandler={handleKeySelect}
                                            changeHandler={() => handleInputChange(key)}
                                            onMenuClearClick={handleClearInputValue}
                                            attributeKey={key}
                                            type='inputbox'
                                            disabled={isConfirmed(key) || !editMode}
                                    />
                                )
                                }
                            </div>
                        </div>
                    ))}
                </form>
            )}

            {selectedFile && <Button size='small'  sx={rightPanelButtonsStyle} 
                                     variant="contained" onClick={() => handleConfirmAll()}
                                     disabled={!editMode} 
                                     title='Confirm if there is not any empty row'>
                                        <Typography variant='caption'>Confirm All</Typography>
                            </Button>}
            {selectedFile && <Button size='small' sx={rightPanelButtonsStyle}  disabled={!editMode} variant="contained" onClick={() => handleUnconfirmAll()}>Un-confirm
                All</Button>}
            {selectedFile &&
                <Button size='small' sx={rightPanelButtonsStyle} variant="contained" 
                    onClick={handleSaveChanges}
                    disabled={!ifAllRequiredAttributesAreConfirmed(extractedValues, confirmedKeys) || !editMode}
                    >Save
                </Button>}
            {selectedFile && isUserEligibleToSubmit() &&
                <Button size='small' sx={rightPanelButtonsStyle} variant="contained"
                    disabled={disableSubmitButton() || !editMode}
                    onClick={handleSubmitChanges}
                >
                    Submit
                </Button>}
            {selectedFile && category === EXTRACTION_CATEGORY &&
                <Button size='small' sx={rightPanelButtonsStyle} variant="contained" 
                    onClick={handleRestoreAttribute} 
                    disabled={isRestoreAttributeBtnDisabled() || !editMode}>Restore attribute</Button>}
            {selectedFile && category === GT_OPS_CONFIRMED_CATEGORY &&
                <Button size='small' disabled={!editMode} sx={rightPanelButtonsStyle} variant="contained" onClick={handleAddNewAttribute}>Add attribute</Button>}

            {isAttributesPopupModalOpen && <AttributesPopupModal 
                isOpen={isAttributesPopupModalOpen}
                onClose={handleAttributesPopupCloseModal}
                file_type={file_type}
                category={category}
                attribute_name={attributeName}
                selectedFile={selectedFile}
            />}

            {isAttributeValidationIssueModalOpen && validationAttributeIssues && <AttributeValidationIssueModal 
                isOpen={isAttributeValidationIssueModalOpen}
                onClose={handleAttributeValidationIssueCloseModal}
                attribute={ removeIndexFromLabel(attributeName)}
                selectedFile={selectedFile}                
                validationAttributeIssues={validationAttributeIssues}
            />}

        </>
    );
};

export default ExtractionConfirmContent;